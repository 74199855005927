<template>
  <div class="homework-page-wrapper flex-row align-center">
    <div
      class="question flex-row align-center justify-center"
      :style="
        currentQuestion.type === 'CHECKBOX'
          ? checkboxQuestionStyle
          : questionStyle
      "
    >
      <div :style="questionTextStyle">
        <span
          :style="questionSkipStyle"
          class="question-skip"
          @click="onComplete"
          >Skip</span
        >
        <p class="question-title">{{ currentQuestion.title }}</p>
        <template v-if="!isHjj">
          <p
            v-for="(option, index) in currentQuestion.options"
            v-show="option.text"
            :key="`${currentQuestionIndex}-${option.text}-${index}`"
            class="question-option-text"
          >
            ({{ ['A', 'B', 'C', 'D'][index] }}) {{ option.text }}
          </p>
        </template>
      </div>
      <div
        v-show="currentQuestion.type === 'CHECKBOX'"
        :style="optionsStyle"
        class="question-options flex-row align-center justify-center"
      >
        <template>
          <div
            v-for="(option, index) in currentQuestion.options"
            v-show="option.text"
            :key="`${currentQuestionIndex}-${option.text}-${index}`"
            class="option"
            :style="
              isOptionHover && hoverOptionIndex === index
                ? Object.assign({}, optionStyle, optionHoverStyle)
                : optionStyle
            "
            @click="onSubmitAnswer(option)"
            @mouseover="
              isOptionHover = true;
              hoverOptionIndex = index;
            "
            @mouseleave="isOptionHover = false"
          >
            {{ isHjj ? option.text : ['A', 'B', 'C', 'D'][index] }}
          </div>
        </template>
      </div>
    </div>
    <div :style="boardStyle">
      <chess-board
        class="chessboard"
        :is-locked="chessboardLocked"
        :custom-board-image-style="boardImageStyle"
        board-color="light"
        :custom-chess-board-style="{
          width: boardStyle.width,
          height: boardStyle.height,
        }"
      >
        <div v-show="showQuestionResult" class="correct-not">
          <correct-or-not
            :size="answerResultSize"
            :result="isCorrect ? 'correct' : ''"
          ></correct-or-not>
        </div>
        <check-to-chess
          :board-helper="baseTsumeHelper"
          :blur-show="!!doubleCheckPosition"
          @cancel="cancelDoubleCheckPosition"
          @confirm="play"
        ></check-to-chess>
      </chess-board>
    </div>
  </div>
</template>

<script>
import delay from '@/lib/base/delay';
import goerUtils from '@/lib/goer-utils/sgf';
import BaseTsumeHelper from './baseTsumeHelper.js';
import ChessBoard from './ChessBoard.vue';
import CorrectOrNot from './CorrectOrNot.vue';
import CheckToChess from './CheckToChess.vue';
const {isSameSgf, combineSgfs, homeworkStandardizeSgf} = goerUtils;
export default {
  components: {
    ChessBoard,
    CorrectOrNot,
    CheckToChess,
  },
  props: {
    getHomeworkPromise: Promise,
    boardStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    boardImageStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    questionStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    checkboxQuestionStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    questionSkipStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    questionTextStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    optionsStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    optionStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    optionHoverStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    answerResultSize: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      showQuestionResult: false,
      chessboardLocked: false,
      answeringMemoryQuestion: false,
      answeringMarkupQuestion: false,
      isSubmittingAnswer: false,
      isCorrect: false,
      checkedAnswers: [],
      homework: {},
      baseTsumeHelper: null,
      currentQuestionIndex: 0,
      sgfCurrentStep: 0,
      sgfTotalStep: 0,
      questionSgfTotalStep: 0,
      status: 'ANSWERING',
      chessType: 'black-first',
      isOptionHover: false,
      hoverOptionIndex: null,
      doubleCheckPosition: null,
    };
  },
  computed: {
    isHjj() {
      return true;
    },
    questions() {
      return this.homework.questions || [];
    },
    currentQuestion() {
      return this.questions[this.currentQuestionIndex] || {};
    },
    laoziSetting() {
      return (
        !this.isHjj &&
        this.$store.getters['user/details'].config &&
        this.$store.getters['user/details'].config.laoziSetting === 'confirm'
      );
    },
  },
  watch: {
    currentQuestionIndex() {
      if (this.status !== 'FINISH') {
        this.setupCurrentQuestion();
      }
    },
  },
  async created() {
    this.$store.commit('env/setIsLoading', true);
    this.homework = this.dataAdapter(await this.getHomeworkPromise);
    if (this.homework.questions.length === 0) {
      this.$alert(this.$t('題目尚未設定'));
    }
    const progress = {
      status: 'ANSWERING',
      questionNumber: 1,
    };

    this.status = progress.status;
    this.currentQuestionIndex = progress.questionNumber - 1;

    this.currentQuestion.reviseCounts = progress.incorrectCounts - 1;
    this.currentQuestion.incorrectCounts = progress.incorrectCounts;

    this.initBoard();
    this.setupCurrentQuestion();

    this.$store.commit('env/setIsLoading', false);
  },
  methods: {
    onSubmitAnswer(option) {
      if (this.isSubmittingAnswer) return;
      if (this.currentQuestion.type === 'CHECKBOX') {
        this.isCorrect = option.isCorrect;
        this.onQuestionDone(option.isCorrect);
      } else {
        this.subSgfAnswer();
      }
    },
    subSgfAnswer() {
      this.isSubmittingAnswer = true;
      if (this.currentQuestion.type === 'MARKUP') {
        this.isCorrect = isSameSgf(
          this.baseTsumeHelper.sgf,
          this.currentQuestion.questionSgf,
          ['M']
        );
      } else {
        this.isCorrect = isSameSgf(
          this.baseTsumeHelper.sgf,
          this.currentQuestion.sgf
        );
      }
      this.onQuestionDone(!!this.isCorrect);
    },
    onQuestionDone(isCorrect) {
      this.currentQuestion.isCorrect = isCorrect;
      this.currentQuestion.submitSgf =
        this.currentQuestion.type === 'CHECKBOX'
          ? null
          : this.baseTsumeHelper.sgf;
      this.currentQuestion.submitOptions =
        this.currentQuestion.type === 'CHECKBOX' ? this.checkedAnswers : null;
      this.showQuestionResult = true;
      if (isCorrect) {
        this.$playSound('correct');
      } else {
        this.$playSound('incorrect');
      }
      delay(700).then(() => {
        this.showQuestionResult = false;
        if (isCorrect) {
          if (this.currentQuestionIndex + 1 === this.questions.length) {
            this.onComplete();
          } else {
            this.currentQuestionIndex++;
            this.checkedAnswers = [];
          }
        } else {
          this.checkedAnswers = [];
          this.setupCurrentQuestion();
        }
        this.isSubmittingAnswer = false;
      });
    },
    onChessTypeClick(chessType) {
      this.chessType = chessType;
      if (this.chessType === 'eraser') {
        this.baseTsumeHelper.setConfig({
          stoneStyle: 'MA',
        });
      } else if (this.chessType === 'black-continuous') {
        // set black first to show correct hover color
        this.baseTsumeHelper.setBlackFirst();
        this.baseTsumeHelper.setConfig({
          stoneStyle: null,
        });
      } else if (this.chessType === 'white-continuous') {
        // set black first to show correct hover color
        this.baseTsumeHelper.setWhiteFirst();
        this.baseTsumeHelper.setConfig({
          stoneStyle: null,
        });
      } else if (this.chessType === 'star-answer-markup') {
        this.baseTsumeHelper.setConfig({
          stoneStyle: 'M',
        });
      } else if (this.chessType === 'eraser-answer-markup') {
        this.baseTsumeHelper.setConfig({
          stoneStyle: 'MA',
        });
      }
    },
    onStartAnswerMemoryQuestion() {
      this.answeringMemoryQuestion = true;
      this.chessboardLocked = false;
      this.baseTsumeHelper.clearBoard();
      this.sgfCurrentStep = 0;
      this.sgfTotalStep = 0;
      this.baseTsumeHelper.showStep('all');
    },
    initBoard() {
      this.baseTsumeHelper = new BaseTsumeHelper('player');
      this.baseTsumeHelper.autoJudgeMarkupTsume = true;

      this.baseTsumeHelper.on('play', () => {
        // this.$playSound('play');
      });
      this.baseTsumeHelper.on('correct', async () => {
        this.isCorrect = true;
        await delay(300);
        this.onQuestionDone(true);
      });
      this.baseTsumeHelper.on('incorrect', async () => {
        this.isCorrect = false;
        await delay(300);
        this.onQuestionDone(false);
      });
      this.baseTsumeHelper.on('update', () => {
        this.sgfCurrentStep = this.baseTsumeHelper.step;
        this.sgfTotalStep = this.baseTsumeHelper.completeStep;
      });
      if (this.laoziSetting) {
        this.baseTsumeHelper.editable.isNoBoldGrid = false;
        this.baseTsumeHelper.setBeforeClick((x, y) => {
          if (this.baseTsumeHelper.isValid(x, y)) {
            this.baseTsumeHelper.editable.frozenHoverStone();
            this.baseTsumeHelper.editable.setHoverStonePosition(x, y);
            this.doubleCheckPosition = {x, y};
          } else {
            this.doubleCheckPosition = null;
          }
          return false;
        });
      } else {
        this.baseTsumeHelper.setBeforeClick((x, y) => {
          return this.play({x, y}, false);
        });
      }
    },
    cancelDoubleCheckPosition() {
      this.baseTsumeHelper.editable.clearHoverStone();
      this.doubleCheckPosition = null;
    },
    play(position, isPlay = true) {
      const checkPosition = position || this.doubleCheckPosition;
      this.cancelDoubleCheckPosition();
      if (checkPosition) {
        const {x, y} = checkPosition;
        if (this.chessType !== '') {
          this.$playSound('play');
          switch (this.chessType) {
            case 'black-continuous':
              this.baseTsumeHelper.addSetup({x, y, c: 1});
              this.baseTsumeHelper.setConfig({
                stoneStyle: null,
              });
              break;
            case 'white-continuous':
              this.baseTsumeHelper.addSetup({x, y, c: -1});
              this.baseTsumeHelper.setConfig({
                stoneStyle: null,
              });
              break;
            case 'star-answer-markup':
              this.baseTsumeHelper.addMarkup({x, y, type: 'M'});
              break;
            default:
              if (isPlay) this.baseTsumeHelper.play(checkPosition);
              return true;
          }
        }
      }
      return false;
    },
    setupCurrentQuestion() {
      this.answeringMemoryQuestion = false;
      this.answeringMarkupQuestion = false;
      switch (this.currentQuestion.type) {
        case 'IMPLEMENT': {
          this.baseTsumeHelper.isReactable = true;
          this.baseTsumeHelper.loadSgf(this.currentQuestion.sgf);
          this.chessboardLocked = false;
          this.baseTsumeHelper.showStep('normal');
          this.chessType = 'black-first';
          this.baseTsumeHelper.setBlackFirst();
          this.baseTsumeHelper.setConfig({
            stoneStyle: null,
          });
          break;
        }
        case 'CHECKBOX': {
          this.baseTsumeHelper.isReactable = true;
          this.baseTsumeHelper.sgf = this.currentQuestion.questionSgf;
          this.chessboardLocked = true;
          this.baseTsumeHelper.showStep('normal');
          break;
        }
        case 'MEMORY': {
          this.baseTsumeHelper.isReactable =
            !this.currentQuestion.isMemoryOrdered;
          this.baseTsumeHelper.sgf = this.currentQuestion.questionSgf;
          this.questionSgfTotalStep = this.baseTsumeHelper.completeStep;
          this.chessboardLocked = true;
          if (this.currentQuestion.isMemoryOrdered) {
            this.baseTsumeHelper.showStep('all');
            this.chessType = 'black-first';
          } else {
            this.chessType = 'black-continuous';
          }
          this.baseTsumeHelper.setBlackFirst();
          this.baseTsumeHelper.setConfig({
            stoneStyle: null,
          });
          break;
        }
        case 'MARKUP': {
          this.baseTsumeHelper.isReactable = false;
          this.baseTsumeHelper.sgf = this.currentQuestion.questionSgf;
          this.baseTsumeHelper.clearDisplayAnswerMarkups();
          this.baseTsumeHelper.editable.board.redraw();
          this.chessboardLocked = true;
          this.answeringMarkupQuestion = true;
          this.chessboardLocked = false;
          this.chessType = 'star-answer-markup';
          this.baseTsumeHelper.setConfig({
            stoneStyle: 'M',
          });
          break;
        }
      }
    },
    dataAdapter(homework) {
      const newHomework = Object.assign({}, homework);

      newHomework.questions = homework.questions.map((question) => {
        question.incorrectCounts = 0;
        question.reviseCounts = 0;
        if (question.type === 'MEMORY') {
          question.title = this.$t('記憶力題型');
        }
        if (question.type === 'IMPLEMENT') {
          const correctSgfs = question.correctSgfs.map((sgf) => {
            return homeworkStandardizeSgf(sgf);
          });
          question.sgf = combineSgfs(correctSgfs);
        } else {
          question.sgf = question.questionSgf;
        }
        return question;
      });
      return newHomework;
    },
    onComplete() {
      this.$emit('onComplete');
    },
  },
};
</script>

<style lang="scss" scoped>
.chessboard {
  margin-right: 0;
}

.correct-not {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.homework-page-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .question {
    color: white;
    .question-skip {
      position: absolute;
      cursor: pointer;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.02;
      letter-spacing: normal;
      color: rgba(255, 255, 255, 0.5);
    }
    .question-title,
    .question-option-text {
      width: 100%;
      line-height: 1.65;
      margin: 0;
    }
    .question-options {
      width: 100%;
      .option {
        background: #36a54e;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-column,
.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
}
</style>
