<template>
  <div class="wrapper">
    <div
      class="chess-board text-center"
      :class="[boardChooseClass(), {aigorithm: clientVersion === 'aigorithm'}]"
      :style="[chessBoardStyle, customChessBoardStyle]"
      @click.stop
    >
      <div
        id="player"
        :style="isLocked ? {'pointer-events': 'none'} : {}"
      ></div>
      <!-- <img :src="board" class="board-image" /> -->
      <div
        class="board-image"
        :style="[
          {'background-color': boardBackgroundColor},
          customBoardImageStyle,
        ]"
        :class="{'test-play': isTestPlay, paused: isPaused}"
      ></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import defaultBoard from '../../assets/img/ObstacleGame/default.png';
import techBoard from '../../assets/img/ObstacleGame/Tech@2x.png';
import {clientVersion} from '@/constant/env';

export default {
  props: {
    customChessBoardStyle: {
      type: Object,
      default: () => ({}),
    },
    boardStyle: {
      type: String,
      default: 'brown', // brown or purple
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    boardColor: {
      type: String,
      default: 'default', // light or default
    },
    customBoardImageStyle: {
      type: Object,
      default: () => ({}),
    },
    isTestPlay: {
      type: Boolean,
      default: false,
    },
    isPaused: {
      type: Boolean,
      default: false,
    },
    chessPile: {
      type: Boolean,
      default: false,
    },
    userColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      clientVersion,
    };
  },
  computed: {
    viewMode() {
      return this.$store.state.env.viewMode;
    },
    width() {
      return this.$store.state.env.width;
    },
    height() {
      return this.$store.state.env.height;
    },
    chessPileSize() {
      return this.viewMode === 'phone' ? 72 : 120;
    },
    board() {
      return this.boardStyle === 'brown' ? defaultBoard : techBoard;
    },
    chessBoardStyle() {
      let style;
      let size;
      switch (this.boardChooseClass()) {
        case 'phone-practice-board':
        case 'phone-study-room-board':
        case 'phone-obstacle-board':
        case 'phone-pvp-board':
          size = this.height - 24;
          style = {
            width: size + 'px',
            height: size + 'px',
          };
          break;
        case 'web-obstacle-board':
        case 'pad-obstacle-board':
          size = Math.min(
            this.height - (48 + 48),
            this.width - (250 + 48 + 48)
          );
          style = {
            width: size + 'px',
            height: size + 'px',
          };
          break;
        case 'web-practice-board':
        case 'pad-practice-board':
        case 'web-pvp-board':
        case 'pad-pvp-board':
        case 'web-study-room-board':
        case 'pad-study-room-board':
          size = Math.min(
            this.height - (32 + 48),
            this.width - (250 + 32 + 48)
          );
          style = {
            width: size + 'px',
            height: size + 'px',
          };
          break;
        case 'web-multimedia-classroom-board':
        case 'pad-multimedia-classroom-board':
          size = Math.min(this.height, this.width - 422) * 0.9;
          style = {
            width: size + 'px',
            height: size + 'px',
          };
          break;
        case 'web-homework':
        case 'pad-homework':
          size = this.height - 48 * 2;
          style = {
            width: size + 'px',
            height: size + 'px',
          };
          break;
        case 'phone-homework':
          size = this.height - 8 * 2;
          style = {
            width: size + 'px',
            height: size + 'px',
          };
          break;
        case 'web-game':
        case 'pad-game':
          size = this.height - 112 - 24 * 2 - 24;
          style = {
            width: size + 'px',
            height: size + 'px',
          };
          break;
        case 'phone-game':
          if (this.orientation === 'landscape') {
            size = this.height - 70 - 16 * 2 - 8;
            style = {
              width: size + 'px',
              height: size + 'px',
            };
          } else {
            size = this.width - 8 * 2;
            style = {
              width: size + 'px',
              height: size + 'px',
            };
          }
          break;
        default:
          style = {};
      }
      return style;
    },
    boardChooseClass() {
      return () => {
        const path = this.$route.name;
        const practicePath = ['tsumego_obstacle_room'];
        const obstaclePath = [
          'game_obstacle_room',
          'game_practice_room',
          'tsumego_practice_room',
          'special-training-room',
        ];
        const pvpPath = ['pvp'];
        const studyRoomPath = ['research'];
        const MultimediaClassroomPath = ['multimediaClassroom'];
        const homeworkPath = [
          'assignment-homework',
          'ai-course',
          'revise-tsume',
        ];
        const gamePath = ['game3x3'];
        if (practicePath.includes(path)) {
          return `${this.viewMode}-practice-board`;
        } else if (obstaclePath.includes(path)) {
          return `${this.viewMode}-obstacle-board`;
        } else if (pvpPath.includes(path)) {
          return `${this.viewMode}-pvp-board`;
        } else if (studyRoomPath.includes(path)) {
          return `${this.viewMode}-study-room-board`;
        } else if (MultimediaClassroomPath.includes(path)) {
          return `${this.viewMode}-multimedia-classroom-board`;
        } else if (homeworkPath.includes(path)) {
          return `${this.viewMode}-homework`;
        } else if (gamePath.includes(path)) {
          return `${this.viewMode}-game`;
        }
      };
    },
    showPlateAndCount() {
      return false;
    },
    settings() {
      return this.$store.getters['user/details'].config;
    },
    boardBackgroundColor() {
      if (this.boardColor == 'light') {
        return '#ffeeca';
      }
      // else if ( this.boardColor == 'default' ) {
      return this.settings && this.settings.checkerboardSetting == 'tech'
        ? '#f5dca5'
        : '#ffd178';
    },
    chessPileStyle() {
      return {
        right: this.width / 2 - this.height / 2 - this.chessPileSize + 'px',
        width: this.chessPileSize + 'px',
      };
    },
    orientation() {
      return this.$store.state.env.orientation;
    },
  },
  methods: {
    leftChessPot(userColor) {
      if (this.$route.name.includes('tsumego')) {
        return 'white';
      } else {
        return userColor == 'black' ? 'white' : 'black';
      }
    },
    rightChessPot(userColor) {
      if (this.$route.name.includes('tsumego')) {
        return 'black';
      } else {
        return userColor == 'black' ? 'black' : 'white';
      }
    },
    firstUpperCase(str) {
      return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
    },
  },
};
</script>

<style lang="scss" scoped>
$color-black-opacity-50: rgba(36, 36, 36, 0.5);
$board-color: #f5dca5;
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .chess-pile-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 16px;
    .chess-pile {
      img {
        width: 100%;
      }
      .out-chess {
        width: 96px;
        height: 26px;
        line-height: 26px;
        border-radius: 13px;
        background: $color-black-opacity-50;
        margin: 8px 14px;
      }
    }
  }
  .chess-board {
    position: relative;
    z-index: 16;
    .board-image {
      z-index: 10;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      background: $board-color;
      // box-shadow: 0 14px 0 $color-cbline-brown-dark, 0 19px 0 rgba(0, 0, 0, 0.3);
      border-radius: 30px;
      &.test-play {
        box-shadow: 0 14px 0 $color-cbline-brown-dark,
          inset 0 0px 0 10px $color-ui-orange, 0 5px 0 rgba(0, 0, 0, 0.3);
      }
      &.paused {
        box-shadow: 0 14px 0 $color-cbline-brown-dark,
          inset 0 0px 0 10px $color-ui-red, 0 5px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }
  .aigorithm {
    &.chess-board {
      .board-image {
        box-shadow: 0 14px 0 #9b6784, 0 5px 0 rgba(#a16939, 0.5);
        &.paused {
          box-shadow: 0 14px 0 #9b6784, inset 0 0px 0 10px #d1554e,
            0 5px 0 rgba(0, 0, 0, 0.3);
        }
        &.test-play {
          box-shadow: 0 14px 0 #9b6784, inset 0 0px 0 10px #fff,
            0 5px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}

.pad-mode {
  .wrapper {
    .chess-pile {
      .chess-pile-empty {
        display: none;
      }
    }
  }
}

.phone-mode {
  .wrapper {
    .chess-pile-wrapper {
      margin-left: 8px;
      .chess-pile {
        .chess-pile-empty {
          display: none;
        }
        .out-chess {
          width: 60px;
          height: 20px;
          line-height: 20px;
          font-size: $font-size-small;
          margin: 8px 8px;
        }
      }
    }
    .chess-board {
      .board-image {
        box-shadow: 0 7px 0 #906624, 0 2px 0 rgba(0, 0, 0, 0.3);
        border-radius: 13px;
        &.test-play {
          box-shadow: 0 7px 0 #906624, inset 0 0px 0 5px $color-ui-orange,
            0 5px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  .wrapper {
    .aigorithm {
      &.chess-board {
        .board-image {
          box-shadow: 0 7px 0 #9b6784, 0 2px 0 rgba(#a16939, 0.5);
          &.paused {
            box-shadow: 0 7px 0 #906624, inset 0 0px 0 7px #d1554e,
              0 5px 0 rgba(0, 0, 0, 0.3);
          }
          &.test-play {
            box-shadow: 0 7px 0 #906624, inset 0 0px 0 5px #fff, 0 5px 0 #9b6784;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
#player {
  width: 100%;
  height: 100%;
  canvas {
    left: 0;
  }
}
</style>

<style lang="scss">
// wgo.js的錯誤顯示
.chess-board {
  .wgo-info-overlay {
    display: none;
  }
}
</style>
