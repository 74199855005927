/* global WGo */
import {
  numberToSgf,
  boardToNumber,
  sgfToNumber,
} from 'goer-utils/function/parser';
import i18n from '@/i18n/index.js';
import {clientVersion} from '@/constant/env';

const isHjj = clientVersion.indexOf('hjj') === 0;
const MARKUP_NUMS = {
  0: '０',
  1: '１',
  2: '２',
  3: '３',
  4: '４',
  5: '５',
  6: '６',
  7: '７',
  8: '８',
  9: '９',
};

class BaseTsumeHelper {
  constructor(elemId, sgf) {
    this.eventHandler = {};
    this.isReactable = true;

    const elem = document.getElementById(elemId);
    this.player = new WGo.BasicPlayer(elem, {
      sgf: sgf || '(;CA[big5]SZ[9])',
      layout: {},
      displayVariations: false,
      board: {
        theme: {
          blackStoneColor: isHjj ? '#4a4141' : '#3D362C',
          mColor: isHjj ? '#ff855e' : '#f0a141',
          mCharCode: 59686,
          gridLinesColor: isHjj ? '#6c4c1f' : '#906624',
          starColor: isHjj ? '#6c4c1f' : '#906624',
          starSize(board) {
            if (isHjj) {
              if (board.stoneRadius < 12) {
                return board.stoneRadius / 8 + 0.5;
              } else {
                return board.stoneRadius / 8 + 4;
              }
            }
            return board.stoneRadius / 8 + 3;
          },
          stoneSize(board) {
            return (
              Math.min(board.fieldWidth, board.fieldHeight) / (isHjj ? 2.2 : 2)
            );
          },
        },
      },
      update: (event) => {
        const {path, node, change, op} = event;
        if (this.editable && this.editable.player) {
          this.call('update');
          this.showStep();
          if (!this.editable.player.kifuReader.node.parent) {
            this.tsumePlayerColoer =
              this.editable.player.kifuReader.node.turn || 1;
          }
        }
        if (change.add.length > 0) {
          this.call('play');
        }
        if (this.isTestPlay) {
          return;
        }
        if (op == 'init') {
          if (
            node &&
            node.children &&
            node.children[0] &&
            node.children[0].move &&
            node.children[0].move.c
          ) {
            this.player.kifuReader.game.turn = node.children[0].move.c;
          }
        }

        if (this.isReactable) {
          if (node.move && node.move.c === this.tsumePlayerColoer) {
            // 如果使用者落子
            this.call('playerLock', true);

            if (path[path.m] > 0 && !this.checkIsPath(node)) {
              // 答錯
              this.call('incorrect');
            } else {
              // 下在可能的解答中
              if (node.children.length == 0) {
                // 沒有後續著手了，已經答題完成
                this.call('correct');
              } else {
                setTimeout(() => {
                  if (
                    this.player.kifuReader.node.children.length == 1 &&
                    !this.player.kifuReader.node.children[0].move &&
                    this.player.kifuReader.node.children[0].children.length > 1
                  ) {
                    this.player.next(0);
                  }
                  this.player.next(
                    Math.floor(
                      Math.random() *
                        this.player.kifuReader.node.children.length
                    )
                  );
                }, 600);
              }
            }
          } else if (node.move && node.move.c === -this.tsumePlayerColoer) {
            this.call('playerLock', false);
          }
        } else if (this.tsumeMarkupAnswers) {
          const mMarkups = this.player.kifuReader.node.markup.filter(
            (node) => node.type == 'M'
          );
          let isIncorrect = false;
          for (const mMarkup of mMarkups) {
            if (
              !this.tsumeMarkupAnswers.some(
                (answer) => answer.x == mMarkup.x && answer.y == mMarkup.y
              )
            ) {
              isIncorrect = true;
              break;
            }
          }
          if (isIncorrect) {
            this.call('incorrect'); // 答錯
          } else if (this.tsumeMarkupAnswers.length == mMarkups.length) {
            this.call('correct'); // 答對
          }
        }
      },
    });
    this.editable = new WGo.Player.Editable(this.player, this.player.board);
    this.setWheel(false);
    this.editable.set(true);
    this.editable.isNoBoldGrid = true;
    this.showStepType = 'normal';
    this.tsumePlayerColoer = 1;
    this.tsumeMarkupAnswers = null;
    this.autoJudgeMarkupTsume = false; // 是否標記題自動判定對錯
  }
  get sgf() {
    const wgoNode = this.editable.player.kifuReader.node;
    const boardSize = this.editable.player.board.size;
    const sgfHead = `(;CA[big5]SZ[${boardSize}]`;
    let sgfBody = '';
    let cursor = wgoNode;
    while (cursor) {
      if (cursor.move) {
        sgfBody =
          ';' +
          numberToSgf(cursor.move.x, cursor.move.y, cursor.move.c) +
          sgfBody;
      }
      if (cursor.setup) {
        const blackMoves = cursor.setup.filter((d) => d.c == 1);
        const whiteMoves = cursor.setup.filter((d) => d.c == -1);
        if (blackMoves.length > 0) {
          let addBlack = 'AB';
          blackMoves.forEach((move) => {
            addBlack += '[' + numberToSgf(move.x, move.y) + ']';
          });
          sgfBody = addBlack + sgfBody;
        }
        if (whiteMoves.length > 0) {
          let addWhite = 'AW';
          whiteMoves.forEach((move) => {
            addWhite += '[' + numberToSgf(move.x, move.y) + ']';
          });
          sgfBody = addWhite + sgfBody;
        }
        // sgfBody = ';' + sgfBody;
      }
      if (cursor.markup) {
        const mMarkup = cursor.markup.filter((d) => d.type === 'M');
        if (mMarkup.length > 0) {
          let addBlack = 'M';
          mMarkup.forEach((move) => {
            addBlack += '[' + numberToSgf(move.x, move.y) + ']';
          });
          sgfBody = addBlack + sgfBody;
        }
      }
      cursor = cursor.parent;
    }
    return sgfHead + sgfBody + ')';
  }
  get config() {
    return this.editable.player.config;
  }

  get step() {
    return this.player.kifuReader.step;
  }

  get completeStep() {
    const root = this.player.kifuReader.kifu.root;
    let node = root;
    let step = 0;
    while (node) {
      if (node.move) {
        step += 1;
      }
      node = node.children[0];
    }
    return step;
  }

  set sgf(sgf) {
    this.loadSgf(sgf);
    this.last();
  }
  get boardWidth() {
    return this.editable.player.kifu.size;
  }
  on(eventName, callback) {
    this.eventHandler[eventName] = callback;
  }
  call(eventName, ...args) {
    if (this.eventHandler[eventName]) {
      this.eventHandler[eventName](...args);
    }
  }
  checkIsPath(node) {
    const move = node.move;
    if (!move) {
      return false;
    }
    const children = node.parent.children.reduce((arr, cur) => {
      if (cur.move && !cur._edited) {
        arr.push(cur.move);
      } else {
        arr.push(...cur.children.map((d) => d.move));
      }
      return arr;
    }, []);
    return children.some((d) => d.x == move.x && d.y == move.y);
  }
  loadSgf(sgf) {
    this.tsumeMarkupAnswers = null;
    this.editable.player.loadSgf(sgf);
    this.editable.player.updateDimensions();

    // 標記題
    if (this.autoJudgeMarkupTsume && this.player.kifuReader.node.markup) {
      const mMarkups = this.player.kifuReader.node.markup.filter(
        (node) => node.type == 'M'
      );
      if (mMarkups.length > 0) {
        this.clearDisplayAnswerMarkups();
        this.tsumeMarkupAnswers = mMarkups;
      }
    }
    this.call('playerLock', false);
    this.setWheel(false);
  }
  setWheel(isAble) {
    this.player.setWheel(isAble);
  }
  setFrozen(isFrozen) {
    return this.editable.player.setFrozen(isFrozen);
  }
  posDiff(oldPosition, newPosition) {
    const size = oldPosition.size;
    const add = [];
    const remove = [];
    for (let i = 0; i < size * size; i++) {
      if (oldPosition.schema[i] && !newPosition.schema[i])
        remove.push({x: Math.floor(i / size), y: i % size});
      else if (oldPosition.schema[i] != newPosition.schema[i])
        add.push({
          x: Math.floor(i / size),
          y: i % size,
          c: newPosition.schema[i],
        });
    }

    return {
      add,
      remove,
    };
  }
  first() {
    this.player.first();
  }
  last() {
    this.player.last();
  }
  prev() {
    this.player.previous();
  }
  prev10() {
    for (let i = 0; i < 10; i++) {
      this.player.previous();
    }
  }
  next() {
    this.player.next();
  }
  next10() {
    for (let i = 0; i < 10; i++) {
      this.player.next();
    }
  }

  showStep(type = this.showStepType) {
    this.clearLastAndNumber();
    if (this.showStepType != type) {
      this.showStepType = type;
    }
    if (this.showStepType == 'last') {
      const node = this.player.kifuReader.node;
      /* 驗證x和y，排除虛手的case */
      if (node.move && node.move.x != null && node.move.y != null) {
        this.player.board.addObject({
          type: 'STEP',
          x: node.move.x,
          y: node.move.y,
          text: this.step,
        });
      }
    } else if (this.showStepType == 'all') {
      let node = this.player.kifuReader.node;
      let step = this.step;
      const boardArray = this.player.board.obj_arr;
      while (node) {
        if (node.move) {
          /* 驗證x和y，排除虛手的case */
          if (node.move.x != null && node.move.y != null) {
            const boardItem = boardArray[node.move.x][node.move.y];
            if (
              boardItem.some((d) => d.c) &&
              boardItem.every((d) => d.type !== 'STEP')
            ) {
              this.player.board.addObject({
                type: 'STEP',
                x: node.move.x,
                y: node.move.y,
                text: step,
              });
            }
          }
          step -= 1;
        }
        node = node.parent;
      }
    } else if (this.showStepType == 'normal') {
      const node = this.player.kifuReader.node;
      if (node.move && node.move.x != null && node.move.y != null) {
        this.player.board.addObject({
          type: 'last',
          x: node.move.x,
          y: node.move.y,
        });
      }
    }
  }

  /* 清除最後一手和數字的標記 */
  clearLastAndNumber() {
    this.player.board.obj_arr.forEach((row) => {
      row.forEach((items) => {
        items.forEach((item) => {
          if (
            item.type === 'last' ||
            (item.type === 'STEP' && Number(item.text) >= 0)
          ) {
            this.player.board.removeObject({
              type: item.type,
              x: item.x,
              y: item.y,
            });
          }
        });
      });
    });
  }

  /* 清空棋盤 */
  clearBoard() {
    this.sgf = `(;CA[big5]SZ[${this.boardWidth}])`;
  }

  addObject({x, y, type, text, c}) {
    const isObjectExist = this.editable.board.obj_arr[x][y].some(
      (d) => d.type == type
    );
    if (isObjectExist) {
      this.editable.board.removeObject({type, x, y});
    } else {
      this.editable.board.addObject({type, x, y, text, c});
    }
  }

  addMarkup({x, y, type, text, c}) {
    const markupList = this.editable.player.kifuReader.node.markup;
    if (markupList && markupList.some((d) => d.x == x && d.y == y)) {
      this.editable.player.kifuReader.node.removeMarkup({x, y});
    } else {
      if (type === '123') {
        this.markupNum++;
        this.editable.player.kifuReader.node.addMarkup({
          x,
          y,
          type: 'LB',
          text: this.convertSafeText(this.markupNum),
          c,
        });
      } else if (type === 'LB') {
        this.editable.player.kifuReader.node.addMarkup({
          x,
          y,
          type,
          text: this.convertSafeText(text),
          c,
        });
      } else {
        this.editable.player.kifuReader.node.addMarkup({x, y, type, text, c});
      }
    }
    this.editable.player.update();
  }
  addSetup(type) {
    if (this.player.kifuReader.path.m == 0) {
      const setupList = this.player.kifuReader.node.setup;
      if (setupList && setupList.some((d) => d.x == type.x && d.y == type.y)) {
        this.player.kifuReader.node.removeSetup(type);
      } else {
        this.player.kifuReader.node.addSetup(type);
      }
      this.player.update();
      this.sgf = this.sgf; // 這是wgo bug 必須這樣做 才會響應
      this.call('markup-change', this.sgf);
    } else {
      alert(i18n.t('只有手數為零的時候才可以連續擺黑或連續擺白'));
    }
  }

  setBlackFirst() {
    this.editable.player.kifuReader.game.turn = 1;
  }
  setWhiteFirst() {
    this.editable.player.kifuReader.game.turn *= -1;
  }
  removeMarkup({x, y}) {
    this.player.kifuReader.node.removeMarkup({x, y});
    this.player.update();
  }
  removeSetup(move) {
    this.player.kifuReader.node.removeSetup(move);
    /* todo: 尋找更有效率的寫法 */
    this.loadSgf(this.sgf);
  }

  convertSafeText(text = '') {
    text = text.toString();
    let safeText = '';
    if (text.indexOf('i-') === 0) {
      safeText = text;
    } else {
      for (const char of text.toString()) {
        if (MARKUP_NUMS[char]) {
          safeText += MARKUP_NUMS[char];
        } else {
          safeText += char;
        }
      }
    }
    return safeText;
  }

  getPromptFromSgf(sgf) {
    const gameNameRegexMatch = sgf.match(new RegExp('GN\\[(.*?)\\]', 'i'));
    const gameName = gameNameRegexMatch && gameNameRegexMatch[1];
    if (gameName) {
      return gameName;
    }

    const gameCommentRegexMatch = sgf.match(new RegExp('GC\\[(.*?)\\]', 'i'));
    const gameComment = gameCommentRegexMatch && gameCommentRegexMatch[1];
    if (gameComment) {
      return gameComment;
    }

    const commentRegexMatch = sgf.match(new RegExp('C\\[(.*?)\\]', 'i'));
    return commentRegexMatch && commentRegexMatch[1];
  }
  /* 會保留當前棋盤手數歷程的試下 */
  // startTestPlay() {
  //   this.isTestPlay = true;

  //   /**
  //    * 做題的試下與一般的試下有所不同。
  //    * 必須將children清空，避免透過試下偷看答案。
  //    */
  //   const kifu = this.editable.player.kifu.clone();
  //   kifu.root.children = [];

  //   this.originalReader = this.editable.player.kifuReader;
  //   this.boardState = this.editable.board.getState();
  //   this.editable.player.kifuReader = new WGo.KifuReader(kifu, this.originalReader.rememberPath, this.originalReader.allow_illegal, this.originalReader.allow_illegal);
  //   this.editable.player.kifuReader.goTo(this.originalReader.path);
  //   this.editable.player.kifuReader.game.turn = this.originalReader.game.turn;
  // }
  startTestPlay() {
    this.isTestPlay = true;

    this.originalReader = this.editable.player.kifuReader;
    this.boardState = this.editable.board.getState();
    this.editable.player.loadSgf(this.flattenSgf());
    this.editable.player.kifuReader.game.turn = this.originalReader.game.turn;

    return {
      originalReader: this.originalReader,
      boardState: this.boardState,
    };
  }
  endTestPlay(
    {originalReader, boardState} = {
      originalReader: this.originalReader,
      boardState: this.boardState,
    }
  ) {
    this.isTestPlay = false;

    this.editable.player.kifuReader = originalReader;
    this.editable.player.kifu = originalReader.kifu;
    this.editable.player.update(true);
    this.editable.board.restoreState(boardState);
    this.originalReader = null;
    this.boardState = null;
  }
  play(move) {
    let x;
    let y;
    if (typeof move == 'string') {
      // D12 A4 之類的....
      const isBoradMove = /[0-9]/.test(move);
      let xy;
      if (isBoradMove) {
        xy = boardToNumber(move, this.boardWidth);
      } else {
        xy = sgfToNumber(move);
      }
      x = xy.x;
      y = xy.y;
    } else {
      x = move.x;
      y = move.y;
    }

    if (this.editable.player.kifuReader.game.isValid(x, y)) {
      this.editable.play(x, y);
    } else {
      throw new Error({message: 'the position is has chess'});
    }
  }
  isValid(x, y) {
    if (this.editable.player.config.stoneStyle === 'M') {
      return true;
    }
    return this.editable.player.kifuReader.game.isValid(x, y);
  }
  setBeforeClick(fun) {
    this.editable.setBeforeClick(fun);
  }

  goto(path) {
    this.editable.player.goTo(path);
  }

  flattenSgf() {
    let addBlack = 'AB';
    let addWhite = 'AW';
    const objectArray = this.editable.player.board.obj_arr;
    objectArray.forEach((row) => {
      row.forEach((objects) => {
        objects.forEach((object) => {
          if (object.c === 1) {
            addBlack += `[${numberToSgf(object.x, object.y)}]`;
          } else if (object.c === -1) {
            addWhite += `[${numberToSgf(object.x, object.y)}]`;
          }
        });
      });
    });

    const newSgf = `(;CA[big5]SZ[${this.boardWidth}]${addBlack}${addWhite})`;

    return newSgf;
  }
  clearNextLabel() {
    this.editable.player.board.obj_arr.forEach((row) => {
      row.forEach((items) => {
        items.forEach((item) => {
          if (
            item.type === 'STEP' &&
            typeof item.text === 'string' &&
            item.c != null
          ) {
            this.player.board.removeObject({
              type: 'STEP',
              x: item.x,
              y: item.y,
            });
          }
        });
      });
    });
  }
  /* displayVariations, lockScroll, markLastMove, rememberPath */
  setConfig(config) {
    for (const attr in config) {
      this.editable.player.config[attr] = config[attr];
    }
  }
  getAnswerResult() {
    const answer = {
      sgf: this.sgf,
      path: this.editable.player.kifuReader.path,
    };
    for (const key in answer.path) {
      answer.path[key] = Number(answer.path[key]);
    }
    return answer;
  }
  clearAllMarkup(markupType, clearLast = false) {
    this.editable.board.obj_arr.forEach((row) => {
      row.forEach((items) => {
        items.forEach((item) => {
          /* 棋子不刪除 */
          if (item.c && !item.type) {
            return;
          }
          /* 最後一手的標記不刪除 */
          const lastMove = this.editable.player.kifuReader.node.move;
          if (
            !clearLast &&
            item.type == 'last' &&
            lastMove &&
            lastMove.x == item.x &&
            lastMove.y == item.y
          ) {
            return;
          }
          if (!markupType || markupType == item.type) {
            this.editable.board.removeObject({
              type: item.type,
              x: item.x,
              y: item.y,
            });
          }
        });
      });
    });
  }
  clearDisplayAllMarkup(
    markupType,
    node = this.editable.player.kifuReader.node
  ) {
    if (node.markup) {
      node.markup = node.markup.filter(({type}) => {
        return type !== markupType;
      });
    }
    for (const key in node.children) {
      this.clearDisplayAllMarkup(markupType, node.children[key]);
    }
  }
  clearDisplayAnswerMarkups() {
    this.clearDisplayAllMarkup('M');
    this.clearAllMarkup('M');
  }
  hideAnswerMarkups() {
    this.editable.board.setNotShowLayer('M');
  }
  showAnswerMarkups() {
    this.editable.board.setShowLayer('M');
  }
}

export default BaseTsumeHelper;
