<template>
  <div
    class="correct-or-not"
    :class="result === 'correct' ? 'bg-success' : 'bg-red'"
    :style="correctOrNotStyle"
  >
    <div
      class="text-white"
      :class="result === 'correct' ? 'icon-Check' : 'icon-X'"
      :style="iconStyle"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    result: {
      type: String, // correct or error
      default: '',
    },
    size: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    correctOrNotStyle() {
      if (this.size == null) return {};

      return {
        width: this.size + 'px',
        height: this.size + 'px',
        borderWidth: (this.size / 300) * 25 + 'px',
      };
    },
    iconStyle() {
      if (this.size == null) return {};

      return {
        fontSize: (this.size / 300) * 150 + 'px',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/_animations.scss';
.correct-or-not {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: exterior 0.4s 0.04s;
  border: solid 20px $color-gray-white;
  .icon-Check,
  .icon-Close {
    font-size: 90px;
    animation: icono 0.6s 0.08s;
  }
}
.phone-mode {
  .correct-or-not {
    border-width: 9px;
    width: 86px;
    height: 86px;
    .icon-Check,
    .icon-Close {
      font-size: 48px;
    }
  }
}
.bg-red {
  background: $color-ui-red;
}
</style>
