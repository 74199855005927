import * as goerUtilSgf from 'goer-utils/function/sgf';

const goerUtilsSgfEx = goerUtilSgf;
goerUtilsSgfEx.homeworkStandardizeSgf = (sgf) => {
  return goerUtilSgf.standardizeSgf(sgf, [
    'SZ',
    'AB',
    'AW',
    'CR',
    'LB',
    'M',
    'SQ',
    'TR',
    'MA',
    'SL',
    'SM',
    'PCR',
    'SCR',
    'PL',
  ]);
};

export default goerUtilsSgfEx;
export * from 'goer-utils/function/sgf';
