var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homework-page-wrapper flex-row align-center"},[_c('div',{staticClass:"question flex-row align-center justify-center",style:(_vm.currentQuestion.type === 'CHECKBOX'
          ? _vm.checkboxQuestionStyle
          : _vm.questionStyle)},[_c('div',{style:(_vm.questionTextStyle)},[_c('span',{staticClass:"question-skip",style:(_vm.questionSkipStyle),on:{"click":_vm.onComplete}},[_vm._v("Skip")]),_c('p',{staticClass:"question-title"},[_vm._v(_vm._s(_vm.currentQuestion.title))]),(!_vm.isHjj)?_vm._l((_vm.currentQuestion.options),function(option,index){return _c('p',{directives:[{name:"show",rawName:"v-show",value:(option.text),expression:"option.text"}],key:`${_vm.currentQuestionIndex}-${option.text}-${index}`,staticClass:"question-option-text"},[_vm._v(" ("+_vm._s(['A', 'B', 'C', 'D'][index])+") "+_vm._s(option.text)+" ")])}):_vm._e()],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentQuestion.type === 'CHECKBOX'),expression:"currentQuestion.type === 'CHECKBOX'"}],staticClass:"question-options flex-row align-center justify-center",style:(_vm.optionsStyle)},[_vm._l((_vm.currentQuestion.options),function(option,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(option.text),expression:"option.text"}],key:`${_vm.currentQuestionIndex}-${option.text}-${index}`,staticClass:"option",style:(_vm.isOptionHover && _vm.hoverOptionIndex === index
                ? Object.assign({}, _vm.optionStyle, _vm.optionHoverStyle)
                : _vm.optionStyle),on:{"click":function($event){return _vm.onSubmitAnswer(option)},"mouseover":function($event){_vm.isOptionHover = true;
              _vm.hoverOptionIndex = index;},"mouseleave":function($event){_vm.isOptionHover = false}}},[_vm._v(" "+_vm._s(_vm.isHjj ? option.text : ['A', 'B', 'C', 'D'][index])+" ")])})],2)]),_c('div',{style:(_vm.boardStyle)},[_c('chess-board',{staticClass:"chessboard",attrs:{"is-locked":_vm.chessboardLocked,"custom-board-image-style":_vm.boardImageStyle,"board-color":"light","custom-chess-board-style":{
          width: _vm.boardStyle.width,
          height: _vm.boardStyle.height,
        }}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showQuestionResult),expression:"showQuestionResult"}],staticClass:"correct-not"},[_c('correct-or-not',{attrs:{"size":_vm.answerResultSize,"result":_vm.isCorrect ? 'correct' : ''}})],1),_c('check-to-chess',{attrs:{"board-helper":_vm.baseTsumeHelper,"blur-show":!!_vm.doubleCheckPosition},on:{"cancel":_vm.cancelDoubleCheckPosition,"confirm":_vm.play}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }