<template>
  <div class="container">
    <div v-show="isLoading" class="prism-player loading-player">
      <div
        class="prism-controlbar"
        style="position: absolute; left: 0px; bottom: 0px; display: block"
      >
        <div class="prism-controlbar-bg"></div>
        <div
          class="prism-progress"
          style="position: absolute; left: 0px; bottom: 44px"
        >
          <div class="prism-progress-marker"></div>
        </div>
        <div
          class="prism-play-btn"
          style="float: left; margin-left: 20px; margin-top: 12px"
        ></div>
        <div
          class="prism-time-display"
          style="float: left; margin-left: 10px; margin-top: 7px"
        ></div>
        <div
          class="prism-setting-btn"
          style="float: right; margin-right: 20px; margin-top: 12px"
        ></div>
        <div
          v-if="viewMode !== 'phone'"
          class="prism-volume"
          style="float: right; margin-right: 5px; margin-top: 10px"
        >
          <div class="volume-icon">
            <div class="short-horizontal"></div>
            <div class="long-horizontal"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!isLoading" :id="playerId" class="prism-player">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/* global Aliplayer */
import {
  getOpenVideoPlayAuth,
  getVideoPlayAuth,
  getPlayInfo,
} from '@/services/aiCourse';
import url from '@/lib/api/url.js';

export default {
  components: {},
  props: {
    aiCourseId: {
      type: String,
      default: null,
    },
    videoId: {
      type: String,
      default: null,
    },
    videoUrl: {
      type: String,
      default: null,
    },
    multimediaClassroomId: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: '',
    },
    progressMarkers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    status: {
      type: String,
      default: '',
    },
    isHideToolbar: Boolean,
    isOpen: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    current: {
      type: Object,
      default: () => ({}),
    },
    coursePlan: {
      type: String,
      default: '',
    },
    courseIndex: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      playerId: null,
      player: null,
      playAuth: {},
      disableStatusWatch: false,
      isLoading: true,
      tickInterval: null,
      curserMarginLeft: 0,
      lastClickEvent: null,
      timer: null,
      isTouchEvent: null,
    };
  },
  computed: {
    viewMode() {
      return this.$store.state.env.viewMode;
    },
    videoOrientation() {
      return this.$store.state.aiCourse.videoOrientation;
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
  },
  watch: {
    isLoading: {
      immediate: true,
      handler() {
        this.$store.commit('env/setIsLoading', this.isLoading);
      },
    },
    progressMarkers: {
      async handler() {
        if (this.player) {
          this.player.setProgressMarkers(this.progressMarkers);
        }
      },
      deep: true,
    },
    status() {
      if (this.disableStatusWatch) return;
      // console.log('Video -> watch -> status', status);
      if (this.status === 'playing') {
        // console.log('Video -> watch -> status -> play video');
        this.player.play();
      } else if (this.status === 'pause') {
        // console.log('Video -> watch -> status -> pause video');
        this.player.pause();
        setTimeout(() => {
          this.player.pause();
        }, 1000);
      }
    },
    isHideToolbar() {
      const controlBar = document.querySelector(
        `#${this.playerId} > .prism-controlbar`
      );
      const playBtn = document.querySelector(
        `#${this.playerId} > .prism-big-play-btn`
      );
      const playBtnAnimation = document.querySelector(
        `#${this.playerId} > .prism-animation`
      );
      if (controlBar == null) return;

      if (this.isHideToolbar) {
        controlBar.style.zIndex = -1;
        playBtn.style.zIndex = -1;
        playBtnAnimation.style.pointerEvents = 'none';
      } else {
        controlBar.style.zIndex = 10;
        playBtn.style.zIndex = 1000;
        playBtnAnimation.style.pointerEvents = '';
      }
    },
  },
  created() {
    this.playerId = `player-${Date.now()}`;
  },
  async mounted() {
    this.init();
    this.tickInterval = setInterval(() => {
      if (this.status === 'playing') {
        const currentTime = this.player?.getCurrentTime();
        if (currentTime) this.$emit('tick', {currentTime});
      }
    }, 10);
    if (this.videoOrientation) {
      this.setScreenOrientation(this.videoOrientation);
    } else {
      this.setScreenOrientation('landscape');
    }
  },
  async beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
    if (this.tickInterval) {
      clearInterval(this.tickInterval);
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
    window.onkeypress = undefined;
  },
  methods: {
    async init() {
      try {
        if (this.videoId != null) {
          const [playAuth, playInfo] = await Promise.all([
            this.isOpen
              ? getOpenVideoPlayAuth(this.videoId)
              : getVideoPlayAuth(this.aiCourseId, this.videoId, null),
            getPlayInfo(this.videoId),
          ]);
          this.playAuth = playAuth;
          this.playInfo = playInfo;
        }
      } catch (error) {
        this.isLoading = false;
        this.$alert('Has no auth');
      }

      const controlBarOptions = {
        name: 'controlBar',
        align: 'blabs',
        x: 0,
        y: 0,
        children: [
          {
            name: 'progress',
            align: 'blabs',
            x: 0,
            y: 44,
          },
          {
            name: 'playButton',
            align: 'tl',
            x: 20,
            y: 12,
          },
          {
            name: 'timeDisplay',
            align: 'tl',
            x: 10,
            y: 7,
          },
          {
            name: 'setting',
            align: 'tr',
            x: 20,
            y: 12,
          },
        ],
      };

      if (this.viewMode !== 'phone') {
        controlBarOptions.children.push({
          name: 'volume',
          align: 'tr',
          x: 5,
          y: 10,
        });
      }

      this.player = new Aliplayer(
        {
          id: this.playerId,
          vid: this.videoId,
          // playauth: this.playAuth.PlayAuth,
          // source: this.videoUrl,
          source: `${url.cdn}ai-course/${this.currentPlan.id}/${this.courseIndex}.m3u8`,
          textTracks: [
            {
              default: true,
              kind: 'subtitles',
              label: '日本語',
              src: `${url.cdn}ai-course/${this.currentPlan.id}/subtitle/ja-JP/${this.courseIndex}.vtt`,
              srclang: 'ja-JP',
            },
            {
              kind: 'subtitles',
              label: '繁體中文',
              src: `${url.cdn}ai-course/${this.currentPlan.id}/subtitle/zh-TW/${this.courseIndex}.vtt`,
              srclang: 'zh-TW',
            },
          ],
          qualitySort: 'asc',
          mediaType: 'video',
          width: '100%',
          height: '100%',
          autoplay: true,
          isLive: false,
          rePlay: false,
          playsinline: true,
          preload: true,
          controlBarVisibility: 'click',
          showBarTime: 4000,
          useH5Prism: true,
          language: 'en-us',
          progressMarkers: this.progressMarkers,
          skinLayout: [
            {
              name: 'bigPlayButton',
              align: 'cc',
            },
            {
              name: 'errorDisplay',
              align: 'tlabs',
              x: 0,
              y: 0,
            },
            {
              name: 'infoDisplay',
            },
            {
              name: 'tooltip',
              align: 'blabs',
              x: 0,
              y: 56,
            },
            controlBarOptions,
          ],
        },
        (player) => {
          if (window.initAiPlayer) {
            window.initAiPlayer(player);
          }
        }
      );

      this.player.on('ready', () => {
        const {videoWidth, videoHeight, duration} = this.player.tag;
        this.videoWidth = videoWidth;
        this.videoHeight = videoHeight;

        this.$emit('onPlayerReady', {
          player: this.player,
          videoWidth,
          videoHeight,
          duration,
          isError: this.videoId != null ? !this.playInfo : false,
        });
        this.isLoading = false;
      });

      this.player.on('play', () => {
        this.disableStatusWatch = true;
        setTimeout(() => {
          this.disableStatusWatch = false;
        }, 100);
        // setTimeout(() => {
        //   if (this.status === 'pause') {
        //     this.player.pause();
        //   }
        // }, 150);
        this.$emit('onStatusChange', {status: 'playing'});
      });

      this.player.on('pause', () => {
        this.disableStatusWatch = true;
        setTimeout(() => {
          this.disableStatusWatch = false;
        }, 100);
        this.$emit('onStatusChange', {status: 'pause'});
      });

      this.player.on('ended', () => {
        this.disableStatusWatch = true;
        setTimeout(() => {
          this.disableStatusWatch = false;
        }, 100);
        this.$emit('onStatusChange', {status: 'end'});
      });

      this.player.on('timeupdate', () => {
        const currentTime = this.player.getCurrentTime();
        const duration = this.player.getDuration();
        const width = window.innerWidth;
        const left = (currentTime / duration) * width;
        if (left < 18) {
          this.curserMarginLeft = `${left - 18}px`;
        } else {
          this.curserMarginLeft = 0;
        }
        this.$emit('onTimeChange', currentTime);
      });
      this.player.on('showBar', () => {
        this.$emit('onControllerShow');
      });

      this.player.on('hideBar', () => {
        this.$emit('onControllerHide');
      });
      window.onkeydown = (e) => {
        console.log('onkeydown', e.code, this.current.value);
        switch (e.code) {
          case 'Space': {
            this.pauseOrPlay();
            break;
          }
          case 'ArrowLeft': {
            this.rewind();
            break;
          }
          case 'ArrowRight': {
            this.fastForward();
            break;
          }
        }
      };
    },
    pauseOrPlay() {
      if (this.player) {
        const status = this.current.value;
        if (status === 'pause' || status === 'ready') {
          this.player.play();
        } else if (status === 'playing') {
          this.player.pause();
        }
      }
    },
    fastForward() {
      if (this.player) {
        const status = this.current.value;
        if (status === 'pause' || status === 'ready' || status === 'playing') {
          const currentTime = this.player.getCurrentTime();
          this.player.seek(currentTime + 5);
        }
      }
    },
    rewind() {
      if (this.player) {
        const status = this.current.value;
        if (status === 'pause' || status === 'ready' || status === 'playing') {
          const currentTime = this.player.getCurrentTime();
          this.player.seek(currentTime - 5);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.prism-player .prism-cover {
  background-size: contain;
}
.prism-player .prism-progress-hover {
  height: 12px;
}
.prism-player .prism-liveshift-progress,
.prism-player .prism-progress {
  height: 12px;
}
.prism-player .prism-liveshift-progress .cursor-hover,
.prism-player .prism-progress .cursor-hover {
  border-radius: 100%;
  width: 36px;
  height: 36px;
  top: -10px !important;
}
.prism-player .prism-liveshift-progress .prism-progress-cursor,
.prism-player .prism-progress .prism-progress-cursor {
  position: absolute;
  border-radius: 100%;
  top: -10px;
  width: 36px;
  height: 36px;
  overflow: hidden;
  box-sizing: content-box;
  display: block !important;
  margin-left: v-bind(curserMarginLeft);
  img {
    width: 100%;
    height: 100%;
  }
}
.prism-player .prism-controlbar {
  z-index: 12;
}
</style>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: black;
  max-width: inherit;
  padding: 0;
  .loading-player {
    height: 100%;
  }
}
</style>
