/* eslint-disable require-jsdoc */
import {callLambda} from '@/lib/api/lambda';
import _ from 'lodash';

function getAiCoursePopulatedTypes() {
  return callLambda({
    category: 'aiCourse',
    method: 'post',
    apiName: 'getPopulatedTypes',
  })
    .then((res) => {
      return res.data.types;
    })
    .then((types) => {
      types.forEach((type) => {
        type.aiCourses.forEach((aiCourse, i) => {
          aiCourse.nextAiCourse = type.aiCourses[i + 1];
          aiCourse.record = aiCourse.record || {};
          aiCourse.record.recordData = aiCourse.record.recordData || {};
        });
      });
      return types;
    });
}

function getAiCourseById({aiCourseId, courseUniqueKey}) {
  const data = {aiCourseId};
  if (courseUniqueKey) {
    data.courseUniqueKey = courseUniqueKey;
  }
  return callLambda({
    category: 'aiCourse',
    method: 'post',
    apiName: 'getAiCourseById',
    data,
  }).then((res) => {
    const aiCourse = res.data.aiCourse;
    aiCourse.record = aiCourse.record || {};
    aiCourse.record.recordData = aiCourse.record.recordData || {};
    return aiCourse;
  });
}

function getOpenVideoPlayAuth(videoId) {
  return callLambda({
    category: 'aiCourse',
    method: 'post',
    apiName: 'getOpenVideoPlayAuth',
    data: {videoId},
  }).then((res) => {
    return res.data.playAuth;
  });
}

function getPlayInfo(videoId) {
  return callLambda({
    category: 'aiCourse',
    method: 'post',
    apiName: 'getPlayInfo',
    data: {videoId},
  }).then((res) => {
    return res.data.playInfo;
  });
}

function updateRecord({
  aiCourseId,
  typeId,
  isCompleted,
  videoTime,
  courseUniqueKey,
}) {
  const data = {aiCourseId, typeId, isCompleted, videoTime};
  if (courseUniqueKey) {
    data.courseUniqueKey = courseUniqueKey;
  }
  return callLambda({
    category: 'aiCourse',
    method: 'post',
    apiName: 'updateRecord',
    data,
  }).then((res) => {
    return res.data.playAuth;
  });
}

/**
 * 取得 Ai 課影片網址與縮圖
 * @param {String} aiCourseId 課程ID
 * @param {String} videoId 影片ID
 * @returns {Promise} promise
 */
const getVideoPlayAuth = _.memoize(function getVideoPlayAuth(
  aiCourseId,
  videoId,
  multimediaClassroomId
) {
  const data = {aiCourseId, videoId};
  if (multimediaClassroomId) {
    data.multimediaClassroomId = multimediaClassroomId;
  }
  return callLambda({
    category: 'aiCourse',
    method: 'post',
    apiName: 'getVideoPlayAuth',
    data,
  }).then((res) => {
    return res.data.playAuth;
  });
});

export default {
  updateRecord,
  getVideoPlayAuth,
  getPlayInfo,
  getOpenVideoPlayAuth,
  getAiCourseById,
  getAiCoursePopulatedTypes,
};
export {
  updateRecord,
  getVideoPlayAuth,
  getPlayInfo,
  getOpenVideoPlayAuth,
  getAiCourseById,
  getAiCoursePopulatedTypes,
};
