<!-- eslint-disable indent -->

<template>
  <div class="ai-course">
    <div class="video">
      <Video
        v-if="videoReload"
        :ai-course-id="aiCourseId"
        :video-id="videoId"
        :mode="mode"
        :multimedia-classroom-id="propsMultimediaClassroomId"
        :video-url="videoUrl"
        :progress-markers="progressMarkers"
        :status="videoStatus"
        :reload-video="reloadVideo"
        :is-hide-toolbar="isHideToolbar"
        :is-open="isOpen"
        :current="current"
        :course-index="courseIndex"
        @tick="videoTick"
        @onStatusChange="onVideoStatusChange"
        @onPlayerReady="onPlayerReady"
        @onControllerShow="onVideoControllerShow"
        @onControllerHide="onVideoControllerHide"
      >
        <div
          class="left"
          @mousedown="clickHandler('left', $event)"
          @touchstart="clickHandler('left', $event)"
        ></div>
        <div
          class="right"
          @mousedown="clickHandler('right', $event)"
          @touchstart="clickHandler('right', $event)"
        ></div>

        <div
          v-if="current && current.value === 'question'"
          class="question-overlay-container"
          :style="questionOverlayContainerStyle"
        >
          <div class="question-container" :style="questionContainerStyle">
            <question
              :get-homework-promise="context.question.getHomeworkPromise"
              :board-style="boardStyle"
              :board-image-style="boardImageStyle"
              :checkbox-question-style="checkboxQuestionStyle"
              :question-style="questionStyle"
              :question-skip-style="questionSkipStyle"
              :question-text-style="questionTextStyle"
              :options-style="optionsStyle"
              :option-style="optionStyle"
              :option-hover-style="optionHoverStyle"
              :answer-result-size="answerResultSize"
              @onComplete="onQuestionComplete"
            ></question>
          </div>
        </div>
      </Video>
      <div v-show="isBackBtnShow && !isHideToolbar" class="back-btn-overlay">
        <div
          class="back-btn icon-Chevron-left"
          @click="goBack"
          @mouseover="isBackBtnHover = true"
          @mouseleave="isBackBtnHover = false"
        ></div>
      </div>
    </div>

    <div
      v-if="current && isEnd"
      class="end-dialog-container"
      :class="{'hjj-bg': isHjj}"
    >
      <div v-if="isHjj" class="custom-ending">
        <img :src="hjjEndPic" />
        <h2>
          {{
            customEndingText ||
            $t('恭喜你完成第 {index} 課', {
              index: getDayText($route.params.day),
            })
          }}
        </h2>
        <button :class="{'jungo-btn': isHjjJungo}" @click="goBack">
          {{ customEndingText ? $t('關閉') : $t('返回首頁') }}
        </button>
      </div>
      <div v-else class="center">
        <img :src="blackboard" />
        <p class="title font-weight-bold">{{ $t('課程結束') }}</p>
        <div class="button-container">
          <button class="primary-2" @click="goBack">
            {{ $t('返回') }}
          </button>
          <button
            v-if="nextAiCourse"
            class="next-ai-course primary-1 view-go-manual"
            @click="go2NextAiCourse"
          >
            {{ $t('看下一課') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Question from '@/components/AiCourse/Question.vue';
import Video from '@/components/AiCourse/Video.vue';
import _ from 'lodash';
import {callLambda} from '@/lib/api/lambda';
import {interpret, Machine, assign} from 'xstate';
import {clientVersion} from '@/constant/env';
import blackboard from '@/assets/img/Base/blackboard.png';
import delay from '@/lib/base/delay';
import {getAiCourseById, updateRecord} from '@/services/aiCourse';
import woodImg from '@/assets/img/Base/wood.png';
import hjjEnd1 from '@/assets/img/hjj-AiCourse/hjjAicourse-1.png';
import hjjEnd2 from '@/assets/img/hjj-AiCourse/hjjAicourse-2.png';
import hjjEnd3 from '@/assets/img/hjj-AiCourse/hjjAicourse-3.png';
import {App} from '@capacitor/app';
import {Device} from '@capacitor/device';

const machine = Machine;
export default {
  name: 'AiCourse',
  components: {
    Question,
    Video,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    customizationBackEvent: {
      type: Boolean,
      default: false,
    },
    propsAiCourseId: {
      type: String,
      default: '',
    },
    propsVideoId: {
      type: String,
      default: '',
    },
    propsMultimediaClassroomId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      aiCourse: {},
      // Interpret the machine and store it in data
      toggleService: null,

      // Start with the machine's initial state
      current: null,

      // Start with the machine's initial context
      context: null,

      resetBoardCounts: 0,
      // videoTickActive: true,
      states: {},
      blackboard,
      reloadVideo: false,
      questionDimensions: null,
      videoWidth: null,
      videoHeight: null,
      isBackBtnShow: true,
      isBackBtnHover: false,
      isVideoControllerShow: false,
      currentTime: 0,
      duration: null,
      isHideToolbar: true,
      courseUniqueKey: null,
      videoReload: false,
      isEnd: false,
      player: null,
      lastClickEvent: null,
      isTouchEvent: null,
    };
  },
  computed: {
    customEndingText() {
      if (this.$route.query.customEndingText) {
        return decodeURIComponent(this.$route.query.customEndingText);
      } else {
        return null;
      }
    },
    courseIndex() {
      return (this.$route.params.courseUniqueKey || '').split('#')[1];
    },
    aiCourseId() {
      return this.$route.params.aiCourseId || this.propsAiCourseId;
    },
    isOpen() {
      return this.$route.query.isOpen === 'true';
    },
    videoId() {
      return this.$route.query.videoId || this.aiCourse.videoId;
    },
    videoUrl() {
      return this.$route.query.videoUrl || this.aiCourse.videoUrl;
    },
    aiCoursePopulatedTypes() {
      return this.$store.state.user.aiCoursePopulatedTypes || [];
    },
    aiCoursePopulatedType() {
      return this.aiCoursePopulatedTypes.find((aiCoursePopulatedType) => {
        return aiCoursePopulatedType.typeId === this.aiCourse.typeId;
      });
    },
    nextAiCourse() {
      const aiCourse = this.aiCoursePopulatedType.aiCourses.find((aiCourse) => {
        return aiCourse.aiCourseId === this.aiCourseId;
      });
      if (this.mode === 'MultimediaClassroom') {
        return false;
      } else if (aiCourse == null) {
        return null;
      } else {
        return aiCourse.nextAiCourse;
      }
    },
    videoStatus() {
      return this.current && this.current.matches('playing')
        ? 'playing'
        : 'pause';
    },
    progressMarkers() {
      if (this.aiCourse.questionsInfos) {
        return this.aiCourse.questionsInfos.map((questionsInfo) => {
          return {
            offset: questionsInfo.time,
            text: questionsInfo.homework.name,
          };
        });
      } else {
        return [];
      }
    },
    isLoggedIn() {
      return this.$store.getters['user/isLogin'];
    },
    isHjj() {
      return clientVersion.indexOf('hjj') === 0;
    },
    isHjjJungo() {
      return clientVersion === 'hjj-jungo';
    },
    width() {
      return this.$store.state.env.width;
    },
    height() {
      return this.$store.state.env.height;
    },
    videoOffsetHeight() {
      if (this.windowDimensionRatio >= this.videoDimensionRatio) {
        return this.height;
      } else {
        return (this.videoHeight * this.videoOffsetWidth) / this.videoWidth;
      }
    },
    videoOffsetWidth() {
      if (this.windowDimensionRatio <= this.videoDimensionRatio) {
        return this.width;
      } else {
        return (this.videoWidth * this.videoOffsetHeight) / this.videoHeight;
      }
    },
    videoDimensionRatio() {
      return this.videoWidth / this.videoHeight;
    },
    windowDimensionRatio() {
      return this.width / this.height;
    },
    isQuestionTitleShow() {
      return (
        this.aiCourse.isQuestionTitleShow == null ||
        this.aiCourse.isQuestionTitleShow
      );
    },
    isQuestionMaskShow() {
      return (
        this.aiCourse.isQuestionMaskShow == null ||
        this.aiCourse.isQuestionMaskShow
      );
    },
    questionOverlayContainerStyle() {
      return {
        background: this.isQuestionMaskShow ? 'rgba(36, 36, 36, 0.8)' : '',
      };
    },
    questionContainerStyle() {
      return {
        width: this.videoOffsetWidth + 'px',
        height: this.videoOffsetHeight + 'px',
      };
    },
    boardStyle() {
      if (!this.questionDimensions) return {};

      const boardWidth = _.round(
        (this.questionDimensions.boardWidthPropotion * this.videoOffsetWidth) /
          100
      );
      const boardTop = _.round(
        (this.questionDimensions.boardTopPropotion * this.videoOffsetHeight) /
          100
      );
      const boardLeft = _.round(
        (this.questionDimensions.boardLeftPropotion * this.videoOffsetWidth) /
          100
      );
      return {
        position: 'absolute',
        top: boardTop + 'px',
        left: boardLeft + 'px',
        width: boardWidth + 'px',
        height: boardWidth + 'px',
      };
    },
    boardImageStyle() {
      if (!this.questionDimensions) return {};

      const boardSize = this.sizeRatio * 15;
      const boardRadious = this.sizeRatio * 30;
      let shadowColor = 'white';
      if (this.isHjjJungo) {
        shadowColor = '#59C3E1';
      } else if (this.isHjj) {
        shadowColor = '#ff855e';
      }
      const style = {
        'border-radius': boardRadious + 'px',
        'box-shadow': `inset 0 0 0 ${boardSize}px ${shadowColor}`,
      };

      if (this.isHjj || this.isHjjJungo) {
        style.background = `url('${woodImg}'`;
      }
      return style;
    },
    checkboxQuestionStyle() {
      if (!this.questionDimensions) return {};

      const paddingTopBottom = this.sizeRatio * 55;
      const paddingLeftRight = this.sizeRatio * 80;
      return {
        ...this.questionStyle,
        justifyContent: 'start',
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
      };
    },
    questionStyle() {
      if (!this.questionDimensions) return {};

      const questionTitleWidth = _.round(
        (this.questionDimensions.questionTitleWidthPropotion *
          this.videoOffsetWidth) /
          100
      );
      const questionTitleHeight = _.round(
        (this.questionDimensions.questionTitleHeightPropotion *
          this.videoOffsetHeight) /
          100
      );
      const questionTitleTop = _.round(
        (this.questionDimensions.questionTitleTopPropotion *
          this.videoOffsetHeight) /
          100
      );
      const questionTitleLeft = _.round(
        (this.questionDimensions.questionTitleLeftPropotion *
          this.videoOffsetWidth) /
          100
      );
      return {
        position: 'absolute',
        top: questionTitleTop + 'px',
        left: questionTitleLeft + 'px',
        width: questionTitleWidth + 'px',
        height: questionTitleHeight + 'px',
        flexWrap: 'wrap',
      };
    },
    questionSkipStyle() {
      if (!this.questionDimensions) return {};

      const fontSize = this.sizeRatio * 37.4;
      const top = this.sizeRatio * 20;
      const right = this.sizeRatio * 20;
      return {
        fontSize: fontSize + 'px',
        top: top + 'px',
        right: right + 'px',
      };
    },
    sizeRatio() {
      return this.videoOffsetHeight / 1080;
      // return 1;
    },
    questionTextStyle() {
      if (!this.isQuestionTitleShow) {
        return {
          display: 'none',
        };
      }

      if (!this.questionDimensions) return {};

      const fontSize = this.sizeRatio * 52.8;
      return {
        fontSize: fontSize + 'px',
        // width: '100%',
      };
    },
    optionsStyle() {
      if (!this.questionDimensions) return {};

      let style = {};
      if (this.isHjj) {
        const bottom = this.sizeRatio * 38;
        style = {
          position: 'absolute',
          left: 0,
          bottom: bottom + 'px',
        };
      } else {
        const marginTop = 40 * this.sizeRatio;
        style = {
          flexWrap: 'wrap',
          width: '100%',
          marginTop: marginTop + 'px',
        };
      }

      return style;
    },
    optionStyle() {
      if (!this.questionDimensions) return {};

      let style = {};
      if (this.isHjj || this.isHjjJungo) {
        const fontSize = this.sizeRatio * 36;
        const height = this.sizeRatio * 87;
        const margin = this.sizeRatio * 8;
        const borderRadius = this.isHjjJungo
          ? this.sizeRatio * 14.5
          : this.sizeRatio * 7.25;
        const minWidth = this.sizeRatio * 143;
        const background = this.isHjjJungo ? '#009E98' : '#ff855e';
        style = {
          background,
          fontSize: fontSize + 'px',
          height: height + 'px',
          lineHeight: height + 'px',
          marginLeft: margin + 'px',
          marginRight: margin + 'px',
          borderRadius: borderRadius + 'px',
          minWidth: minWidth + 'px',
          fontWeight: 'bold',
        };
      } else {
        const fontSize = this.sizeRatio * 38.7;
        const height = this.sizeRatio * 87;
        const margin = this.sizeRatio * 20;
        const borderRadius = this.sizeRatio * 60.4;
        const minWidth = this.sizeRatio * 200;
        const marginBottom = this.sizeRatio * 24;
        style = {
          background: '#f0a141',
          fontSize: fontSize + 'px',
          height: height + 'px',
          lineHeight: height + 'px',
          marginLeft: margin + 'px',
          marginRight: margin + 'px',
          borderRadius: borderRadius + 'px',
          minWidth: minWidth + 'px',
          marginBottom: marginBottom + 'px',
        };
      }

      return style;
    },
    optionHoverStyle() {
      let background = '#feb55e';
      if (this.isHjjJungo) {
        background = '#00ABA5';
      } else if (this.isHjj) {
        background = '#e57754';
      }
      return {
        background,
      };
    },
    answerResultSize() {
      return this.sizeRatio * 300;
    },
    hjjEndPic() {
      return _.sample([hjjEnd1, hjjEnd2, hjjEnd3]);
    },
  },
  watch: {
    async aiCourseId() {
      this.current = null;
      await this.init();
      this.reloadVideo = true;
      await this.$nextTick();
      this.reloadVideo = false;
    },
    height: {
      handler: (height) => {
        document.body.style.height = `${height}px`;
      },
      immediate: true,
    },
  },
  async mounted() {
    if (this.isHjj) {
      this.courseUniqueKey = this.$route.params.courseUniqueKey;
      window.hjjUpdateRecord = this.updateRecord;
    }
    if (this.$store.state.user.aiCoursePopulatedTypes == null && !this.isHjj) {
      await this.$store.dispatch('user/getAiCoursePopulatedTypes');
    }
    await this.init();
  },
  async beforeDestroy() {
    // 黑嘉嘉的更新紀錄部分由黑嘉嘉專案內呼叫
    if (!this.isHjj) {
      await this.updateRecord();
    }
  },
  methods: {
    async init() {
      this.isEnd = false;
      const apiData = {
        aiCourseId: this.aiCourseId,
      };
      if (this.isHjj) {
        apiData.courseUniqueKey = this.courseUniqueKey;
      }
      this.aiCourse = await getAiCourseById(apiData);
      this.resetQuestionInfosStatus();
      if (window.onHjjAiCourseReady) {
        window.onHjjAiCourseReady({
          aiCourseId: this.aiCourse.aiCourseId,
          typeId: this.aiCourse.typeId,
          courseUniqueKey: this.courseUniqueKey,
        });
      }
      this.questionDimensions = this.aiCourse.questionDimensions;
      const questionsStates = {};
      this.aiCourse.questionsInfos.forEach((questionInfo) => {
        questionsStates[`TIME_AT_${questionInfo.time}S`] = {
          target: 'question',
          actions: assign({
            question: {
              getHomeworkPromise: this.getHomework(questionInfo.homework.id),
            },
          }),
        };
      });
      this.states = {
        ready: {
          on: {
            PLAY: 'playing',
          },
        },
        pause: {
          on: {
            PLAY: 'playing',
            END: 'end',
          },
        },
        playing: {
          entry: assign({
            question: null,
          }),
          on: {
            PAUSE: 'pause',
            ...questionsStates,
          },
        },
        end: {
          on: {
            PAUSE: 'pause',
          },
        },
        question: {
          on: {
            COMPLETE_QUESTION: 'playing',
          },
        },
      };

      // console.log('this.states', this.states);
      // This machine is completely decoupled from Vue
      const interactiveVideoMachine = machine(
        {
          id: 'interactiveVideo',
          context: {
            /* some data */
          },
          initial: 'ready',
          states: this.states,
        },
        {
          actions: {
            // action implementations
          },
        }
      );
      this.toggleService = interpret(interactiveVideoMachine);
      this.current = interactiveVideoMachine.initialState;
      this.context = interactiveVideoMachine.context;

      // Start service on component creation
      this.toggleService
        .onTransition((state) => {
          // Update the current state component data property with the next state
          this.current = state;
          // console.log(`Current state: ${state.value}`);
          // Update the context component data property with the updated context
          this.context = Object.assign({}, state.context);
          if (this.current.value === 'question') {
            this.isHideToolbar = true;
            this.onVideoControllerHide();
          } else {
            this.isHideToolbar = false;
          }
        })
        .start();

      this.videoReload = false;
      await this.$nextTick();
      this.videoReload = true;
      const deviceInfo = await Device.getInfo();
      if (deviceInfo.platform === 'android') {
        App.addListener('backButton', this.goBack);
      }
    },
    // Send events to the service
    send(event) {
      console.log(`- XState - Send event: ${event}`);
      if (this.toggleService != null) {
        this.toggleService.send(event);
      }
    },
    getHomework(homeworkId) {
      return callLambda({
        category: 'homework',
        apiName: `homeworks/${homeworkId}`,
        method: 'get',
      }).then((res) => {
        return res.data;
      });
    },
    onQuestionComplete() {
      this.send('COMPLETE_QUESTION');
    },
    videoTick({currentTime}) {
      const isSeekChange = Math.abs(this.currentTime - currentTime) > 0.5;
      // const time = _.round(currentTime, 2);
      this.currentTime = currentTime;
      if (isSeekChange) {
        this.resetQuestionInfosStatus();
      }
      if (window.onHjjAiCourseTimeUpdate) {
        window.onHjjAiCourseTimeUpdate({
          videoTime: currentTime,
          isCompleted: this.checkIsCompleted(),
        });
      }

      for (const questionInfo of this.aiCourse.questionsInfos) {
        if (
          currentTime > questionInfo.time &&
          questionInfo.status === 'pendding'
        ) {
          questionInfo.status = 'done';
          const event = `TIME_AT_${questionInfo.time}S`;
          this.send(event);
          document.getElementsByTagName('video')[0].currentTime = parseFloat(
            questionInfo.time
          );
          break;
        }
      }
    },
    resetQuestionInfosStatus() {
      for (const questionInfo of this.aiCourse.questionsInfos) {
        questionInfo.status =
          this.currentTime > questionInfo.time ? 'done' : 'pendding';
      }
    },
    onVideoStatusChange({status}) {
      this.status = status;
      if (this.status === 'playing') {
        this.isEnd = false;
        this.send('PLAY');
      } else if (this.status === 'pause') {
        this.send('PAUSE');
      } else if (this.status === 'end') {
        this.isEnd = true;
        this.send('END');
      }
    },
    async onPlayerReady({player, videoWidth, videoHeight, duration}) {
      const recordData = this.aiCourse.record?.recordData;
      if (
        recordData != null &&
        recordData.videoTime != null &&
        duration - recordData.videoTime > 10
      ) {
        player.seek(
          recordData.videoTime - 5 >= 0 ? recordData.videoTime - 5 : 0
        );
      }
      this.player = player;
      this.videoWidth = videoWidth;
      this.videoHeight = videoHeight;
      this.duration = duration;
    },
    goBack() {
      this.onHjjGoBack();
    },
    go2NextAiCourse() {
      this.$router.replace(
        `/ai-course/${this.nextAiCourse.aiCourseId}?videoId=${this.nextAiCourse.videoId}`
      );
    },
    onVideoControllerShow() {
      this.isVideoControllerShow = true;
      this.isBackBtnShow = true;
    },
    async onVideoControllerHide() {
      this.isVideoControllerShow = false;
      if (this.isBackBtnHover) {
        await delay(1000 * 5);
        this.isBackBtnShow = this.isVideoControllerShow;
      } else {
        this.isBackBtnShow = false;
      }
    },
    checkIsCompleted() {
      return (
        this.aiCourse.record.recordData.isCompleted ||
        this.duration - this.currentTime < 10
      );
    },
    async updateRecord() {
      if (!this.isLoggedIn) return;
      const isCompleted = this.checkIsCompleted();
      const apiData = {
        aiCourseId: this.aiCourse.aiCourseId,
        typeId: this.aiCourse.typeId,
        isCompleted,
        videoTime: this.currentTime,
        courseUniqueKey: this.courseUniqueKey,
      };
      await updateRecord(apiData);
    },
    getDayText(day) {
      return String(day).split('.').join('-');
    },
    async onHjjGoBack() {
      if (this.$device.isTablet) {
        this.setScreenOrientation('landscape');
      } else {
        this.setScreenOrientation('portrait');
      }
      this.$store.commit('aiCourse/setVideoOrientation', null);
      this.$router.go(-1);
      await this.updateRecord();
    },
    pauseOrPlay() {
      if (this.player) {
        const status = this.current.value;
        if (status === 'pause' || status === 'ready') {
          this.player.play();
        } else if (status === 'playing') {
          this.player.pause();
        }
      }
    },
    fastForward() {
      if (this.player) {
        const status = this.current.value;
        if (status === 'pause' || status === 'ready' || status === 'playing') {
          const currentTime = this.player.getCurrentTime();
          this.player.seek(currentTime + 5);
        }
      }
    },
    rewind() {
      if (this.player) {
        const status = this.current.value;
        if (status === 'pause' || status === 'ready' || status === 'playing') {
          const currentTime = this.player.getCurrentTime();
          this.player.seek(currentTime - 5);
        }
      }
    },
    clickHandler(position, e) {
      if (e.type === 'touchstart') this.isTouchEvent = true;
      if (this.isTouchEvent === null && e.type === 'mousedown')
        this.isTouchEvent = false;
      if (this.isTouchEvent && e.type !== 'touchstart') return;
      if (this.isTouchEvent === false && e.type !== 'mousedown') return;
      const now = new Date().getTime();
      const doubleClickTime = 600;
      this.pauseOrPlay();
      if (
        this.lastClickEvent &&
        this.lastClickEvent.position === position &&
        now - this.lastClickEvent.time < doubleClickTime
      ) {
        if (position === 'left') this.rewind();
        else if (position === 'right') this.fastForward();
      }
      this.lastClickEvent = {
        position,
        time: now,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.ai-course {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.back-btn-overlay {
  z-index: 12;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100px;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0) 100%
  );
  .back-btn {
    position: absolute;
    left: 16px;
    top: calc(env(safe-area-inset-top) + 16px);
    pointer-events: all;
    color: $black;
    background: $white;
    cursor: pointer;
    border: 5px solid rgba(255, 255, 255, 0.51);
    border-radius: 100%;
    font-weight: 800;
    font-size: 37px;
    padding-top: 2px;
    width: 50px;
    height: 50px;
  }
}

.phone-mode {
  .back-btn-overlay {
    .back-btn {
      font-size: 37px;
    }
  }
}

.video {
  position: relative;
  // width: 500px;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: black;
}

.question-overlay-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.end-dialog-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-gray-dark-opacity-80;
  &.hjj-bg {
    background: rgba(0, 0, 0, 0.6);
  }
  .custom-ending {
    color: white;
    img {
      display: block;
      margin: 0 auto;
      width: 220px;
      margin-bottom: 8px;
      @media (max-width: 570px) {
        width: 180px;
      }
    }
    h2 {
      text-align: center;
      font-size: 28px;
      margin-top: 0;
      margin-bottom: 8px;
    }
    button {
      cursor: pointer;
      border-radius: 4px;
      font-size: 16px;
      background: #ffc069;
      color: white;
      border: none;
      width: 94px;
      line-height: 38px;
      margin: 0 auto;
      padding: 0;
      display: block;
      &.jungo-btn {
        background: #009e98;
      }
    }
  }
  .center {
    position: relative;
    .title {
      position: absolute;
      text-align: center;
      top: 80px;
      font-size: 60px;
      color: white;
      width: 100%;
      margin: 0;
      letter-spacing: 10px;
    }
    .button-container {
      position: absolute;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 76px;
      .next-ai-course {
        width: 117px;
      }
    }
  }
}

.phone-mode {
  .end-dialog-container {
    .center {
      width: 450px;
      height: 300px;
      img {
        width: 100%;
      }
      .title {
        top: 90px;
        font-size: 36px;
      }
    }
  }
}
.left,
.right {
  position: absolute;
  width: 50%;
  height: 100%;
  z-index: 1;
}
.right {
  right: 0;
}
</style>
