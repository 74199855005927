<template>
  <div
    class="check-to-chess bg-white-half-opacity"
    :class="{'slide-in': blurShow, aigorithm: clientVersion === 'aigorithm'}"
  >
    <div class="btn-wrap">
      <div class="lite-hide">
        <button type="primary" round class="cancel primary-2" @click="cancel">
          <span>{{ $t('取消') }}</span>
        </button>
        <button type="primary" round class="confirm primary-1" @click="confirm">
          <span>{{ $t('確定') }}</span>
        </button>
      </div>
      <div>
        <button class="oval move-chese btn-circle" @click="moveChese('up')">
          <i class="icon-RegularUp"></i>
        </button>
        <button class="oval move-chese btn-circle" @click="moveChese('down')">
          <i class="icon-RegularDown"></i>
        </button>
        <button class="oval move-chese btn-circle" @click="moveChese('left')">
          <i class="icon-RegularLeft"></i>
        </button>
        <button class="oval move-chese btn-circle" @click="moveChese('right')">
          <i class="icon-RegularRight"></i>
        </button>
        <button
          class="oval lite-show cancel primary-2 btn-circle"
          @click="cancel"
        >
          <i class="icon-Close"></i>
        </button>
        <button
          class="oval lite-show confirm primary-1 btn-circle"
          @click="confirm"
        >
          <i class="icon-Check"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {clientVersion} from '@/constant/env';

export default {
  props: {
    boardHelper: {
      type: Object,
      default: () => ({}),
    },
    blurShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      position: null,
      clientVersion,
    };
  },
  methods: {
    cancel() {
      this.position = null;
      this.$emit('cancel');
    },
    confirm() {
      const position = this.boardHelper.editable._last_mark;
      if (this.boardHelper.isValid(position.x, position.y)) {
        this.$emit('confirm', position);
        this.position = null;
      }
    },
    moveChese(direction) {
      if (this.boardHelper) {
        const size = this.boardHelper.editable.board.size;
        const originPosition = this.boardHelper.editable._last_mark;
        let {x, y} = originPosition;
        switch (direction) {
          case 'up':
            y -= 1;
            break;
          case 'down':
            y += 1;
            break;
          case 'left':
            x -= 1;
            break;
          case 'right':
            x += 1;
            break;
          default:
            return;
        }
        if (x >= size || y >= size || x < 0 || y < 0) return;
        // console.log(x, y);
        // if (this.boardHelper.isValid(x, y)) {
        this.position = {x, y};
        this.boardHelper.editable.setHoverStonePosition(x, y);
        if (!this.boardHelper.editable._last_mark) {
          this.boardHelper.editable._last_mark = Object.assign(
            {},
            originPosition,
            this.position
          );
        }
        // }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.check-to-chess {
  width: 200px;
  height: 88px;
  border-radius: 0 30px 30px 0;
  position: fixed;
  bottom: 0px;
  z-index: 999;
  left: 0;
  // margin-left: -248px;
  margin-left: -200px;
  transition-duration: 0.2s;
  visibility: hidden;
  opacity: 0;
  .oval {
    margin: 8px;
    padding: 0px;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    display: inline-block;
  }
  .btn-wrap {
    align-items: center;
    ::v-deep .cancel.button.is-round.button.is-round {
      // background-color: #bdbdbd;
      border: none;
      padding: 4px 32px 10px;
      margin: 8px;
      min-width: auto;
    }
    ::v-deep .confirm.button.is-round.button.is-round {
      padding: 4px 32px 10px;
      margin: 8px;
      min-width: auto;
    }

    & > div {
      white-space: nowrap;
    }
  }
  &.lite {
    width: 100%;
    height: auto;
    bottom: 0px;
    margin-left: 0px;
    background: none;
    .move-chese {
      background-color: $color-brand-secondary-dark;
    }
    .lite-hide {
      display: none;
    }
    .lite-show {
      display: inline-block;
    }
    .oval {
      width: 36px;
      height: 36px;
    }
  }
  &:not(.lite) {
    padding: 3px 16px 3px 8px;
    padding: 3px 8px 3px 6px;
    .oval {
      width: 30px;
      height: 30px;

      // width: 40px;
      // height: 40px;
      box-shadow: none;
      background-color: $color-brand-secondary-light-brown;
      &:hover {
        background-color: $color-brand-secondary-natural;
      }
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
    .oval {
      margin: 5px;
    }
    .btn-wrap {
      align-items: center;
      ::v-deep .cancel.button.is-round.button.is-round {
        padding: 4px 24px 6px;
        margin: 5px;
        min-width: auto;
      }
      ::v-deep .confirm.button.is-round.button.is-round {
        padding: 4px 24px 6px;
        margin: 5px;
        min-width: auto;
      }
    }
    .lite-show {
      display: none;
    }
  }
  &.slide-in {
    margin-left: 0;
    visibility: visible;
    opacity: 1;
  }
}
.aigorithm {
  &.check-to-chess.slide-in {
    background: #d9ddff;
    border: 3px solid white;
    border-left-width: 0;
  }
}
</style>
