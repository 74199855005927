import { render, staticRenderFns } from "./ChessBoard.vue?vue&type=template&id=1c3a7619&scoped=true&"
import script from "./ChessBoard.vue?vue&type=script&lang=js&"
export * from "./ChessBoard.vue?vue&type=script&lang=js&"
import style0 from "./ChessBoard.vue?vue&type=style&index=0&id=1c3a7619&prod&lang=scss&scoped=true&"
import style1 from "./ChessBoard.vue?vue&type=style&index=1&id=1c3a7619&prod&lang=scss&"
import style2 from "./ChessBoard.vue?vue&type=style&index=2&id=1c3a7619&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c3a7619",
  null
  
)

export default component.exports